import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { CustomContext } from "../contexts/ProductUsersContext";
import HomeIcon from "../../image/menuItems/home.svg";
import CatalogIcon from "../../image/menuItems/catalog.svg";
import BucketIcon from "../../image/menuItems/cart.svg";
import UserIcon from "../../image/menuItems/profile.svg";
import "./navlist.scss";
import axios from "axios";
import {BASE_URL} from "../../utils/Constants";

function Footerbar() {
  const { order,setOrders, setUserPersonalData, setHistoryOrder} = useContext(CustomContext);
  const [localCartItems] = useState(() => {
    const storedItems = localStorage.getItem("cartItems");
    return storedItems ? JSON.parse(storedItems) : [];
  });

  useEffect(() => {
    setOrders(localCartItems);
  }, [localCartItems]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userPersonalData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserPersonalData(userData);
    }
  }, []);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userPersonalData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      axios
          .post(`${BASE_URL}/local/API/v0.1/order/history/get/`, {
            userId: userData?.userData?.id,
          })
          .then((response) => {
            setHistoryOrder(response.data.orders);
          });
    }
  }, []);

  return (
    <div className="navigation--list">
      <div className="navigation__content">
        <div className="navigation__item ps-toggle--sidebar1">
          <Link to={"/"}>
            <img src={HomeIcon} alt="" className="icon" />
          </Link>
          <span> Главная</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar">
          <Link to={"/catalog"}>
            <img src={CatalogIcon} alt="" className="icon" />
          </Link>
          <span> Категории</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar">
          <Link to={"/cart"}>
            <img src={BucketIcon} alt="" className="icon" />
          </Link>
          <span className="orderSize">{order.length}</span>
          <span> Корзина</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar1">
          <Link to={"/login"}>
            <img src={UserIcon} alt="" className="icon" />
          </Link>
          <span> Профиль</span>
        </div>
      </div>
    </div>
  );
}

export default Footerbar;




const a = `
object(Bitrix\\Sale\\Order)#194 (19) {
  ["fields":protected]=>
  object(Bitrix\\Sale\\Internals\\Fields)#203 (5) {
    ["values":protected]=>
    array(15) {
      ["CANCELED"]=>
      string(1) "N"
      ["DEDUCTED"]=>
      string(1) "N"
      ["ALLOW_DELIVERY"]=>
      string(1) "N"
      ["PAYED"]=>
      string(1) "N"
      ["UPDATED_1C"]=>
      string(1) "N"
      ["LID"]=>
      string(2) "s1"
      ["USER_ID"]=>
      int(26)
      ["CURRENCY"]=>
      string(3) "RUB"
      ["STATUS_ID"]=>
      string(1) "N"
      ["DATE_STATUS"]=>
      object(Bitrix\\Main\\Type\\DateTime)#798 (2) {
        ["value":protected]=>
        object(DateTime)#797 (3) {
          ["date"]=>
          string(26) "2024-12-26 11:51:34.449953"
          ["timezone_type"]=>
          int(3)
          ["timezone"]=>
          string(13) "Europe/Moscow"
        }
        ["userTimeEnabled":protected]=>
        bool(true)
      }
      ["XML_ID"]=>
      string(16) "bx_676d19166e10f"
      ["PERSON_TYPE_ID"]=>
      int(1)
      ["PRICE"]=>
      float(95)
      ["SUM_PAID"]=>
      float(0)
      ["USER_DESCRIPTION"]=>
      string(33) "свяжитель со мной!"
    }
    ["changedValues":protected]=>
    array(11) {
      ["UPDATED_1C"]=>
      bool(true)
      ["LID"]=>
      bool(true)
      ["USER_ID"]=>
      bool(true)
      ["CURRENCY"]=>
      bool(true)
      ["STATUS_ID"]=>
      bool(true)
      ["DATE_STATUS"]=>
      bool(true)
      ["XML_ID"]=>
      bool(true)
      ["PERSON_TYPE_ID"]=>
      bool(true)
      ["SUM_PAID"]=>
      bool(true)
      ["PRICE"]=>
      bool(true)
      ["USER_DESCRIPTION"]=>
      bool(true)
    }
    ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
    array(11) {
      ["UPDATED_1C"]=>
      NULL
      ["LID"]=>
      NULL
      ["USER_ID"]=>
      NULL
      ["CURRENCY"]=>
      NULL
      ["STATUS_ID"]=>
      NULL
      ["DATE_STATUS"]=>
      NULL
      ["XML_ID"]=>
      NULL
      ["PERSON_TYPE_ID"]=>
      NULL
      ["SUM_PAID"]=>
      NULL
      ["PRICE"]=>
      float(0)
      ["USER_DESCRIPTION"]=>
      NULL
    }
    ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
    array(0) {
    }
    ["isClone":protected]=>
    bool(false)
  }
  ["eventName":protected]=>
  string(9) "SaleOrder"
  ["calculatedFields":protected]=>
  object(Bitrix\\Sale\\Internals\\Fields)#202 (5) {
    ["values":protected]=>
    array(5) {
      ["USE_VAT"]=>
      string(1) "N"
      ["VAT_RATE"]=>
      int(0)
      ["VAT_SUM"]=>
      int(0)
      ["VAT_DELIVERY"]=>
      int(0)
      ["TAX_PRICE"]=>
      int(0)
    }
    ["changedValues":protected]=>
    array(5) {
      ["USE_VAT"]=>
      bool(true)
      ["VAT_RATE"]=>
      bool(true)
      ["VAT_SUM"]=>
      bool(true)
      ["VAT_DELIVERY"]=>
      bool(true)
      ["TAX_PRICE"]=>
      bool(true)
    }
    ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
    array(5) {
      ["USE_VAT"]=>
      NULL
      ["VAT_RATE"]=>
      NULL
      ["VAT_SUM"]=>
      NULL
      ["VAT_DELIVERY"]=>
      NULL
      ["TAX_PRICE"]=>
      NULL
    }
    ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
    array(0) {
    }
    ["isClone":protected]=>
    bool(false)
  }
  ["basketCollection":protected]=>
  object(Bitrix\\Sale\\Basket)#264 (12) {
    ["collection":protected]=>
    array(1) {
      [0]=>
      object(Bitrix\\Sale\\BasketItem)#265 (11) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#266 (5) {
          ["values":protected]=>
          array(27) {
            ["MODULE"]=>
            string(7) "catalog"
            ["BASE_PRICE"]=>
            float(95)
            ["CAN_BUY"]=>
            string(1) "Y"
            ["VAT_RATE"]=>
            NULL
            ["CUSTOM_PRICE"]=>
            string(1) "N"
            ["PRODUCT_ID"]=>
            string(5) "25247"
            ["XML_ID"]=>
            string(16) "bx_676d191661847"
            ["LID"]=>
            string(2) "s1"
            ["SORT"]=>
            int(100)
            ["QUANTITY"]=>
            int(1)
            ["CURRENCY"]=>
            string(3) "RUB"
            ["PRODUCT_PROVIDER_CLASS"]=>
            string(39) "\\Bitrix\\Catalog\\Product\\CatalogProvider"
            ["VAT_INCLUDED"]=>
            string(1) "N"
            ["PRODUCT_PRICE_ID"]=>
            string(4) "6101"
            ["NOTES"]=>
            string(18) "Розничный"
            ["PRICE_TYPE_ID"]=>
            string(1) "4"
            ["PRICE"]=>
            float(95)
            ["DISCOUNT_PRICE"]=>
            float(0)
            ["CATALOG_XML_ID"]=>
            string(36) "1b9d03d6-7b26-444e-9a57-2d4180d68aaf"
            ["PRODUCT_XML_ID"]=>
            string(36) "b02ffa14-434c-11ee-8218-4ccc6a91af8f"
            ["NAME"]=>
            string(83) "Щетка шлифовальная DE-TERO Flex MB2 30*40/45*3мм 141 Р100 400J "
            ["DETAIL_PAGE_URL"]=>
            string(31) "/catalog/abraziv/de_tero/25247/"
            ["MEASURE_NAME"]=>
            string(4) "шт"
            ["MEASURE_CODE"]=>
            string(3) "796"
            ["BARCODE_MULTI"]=>
            string(1) "N"
            ["WEIGHT"]=>
            float(0)
            ["DIMENSIONS"]=>
            string(50) "a:3:{s:5:"WIDTH";N;s:6:"HEIGHT";N;s:6:"LENGTH";N;}"
          }
          ["changedValues":protected]=>
          array(20) {
            ["SORT"]=>
            bool(true)
            ["QUANTITY"]=>
            bool(true)
            ["CURRENCY"]=>
            bool(true)
            ["PRODUCT_PROVIDER_CLASS"]=>
            bool(true)
            ["VAT_INCLUDED"]=>
            bool(true)
            ["PRODUCT_PRICE_ID"]=>
            bool(true)
            ["NOTES"]=>
            bool(true)
            ["PRICE_TYPE_ID"]=>
            bool(true)
            ["BASE_PRICE"]=>
            bool(true)
            ["PRICE"]=>
            bool(true)
            ["DISCOUNT_PRICE"]=>
            bool(true)
            ["CATALOG_XML_ID"]=>
            bool(true)
            ["PRODUCT_XML_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["DETAIL_PAGE_URL"]=>
            bool(true)
            ["MEASURE_NAME"]=>
            bool(true)
            ["MEASURE_CODE"]=>
            bool(true)
            ["BARCODE_MULTI"]=>
            bool(true)
            ["WEIGHT"]=>
            bool(true)
            ["DIMENSIONS"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(20) {
            ["SORT"]=>
            NULL
            ["QUANTITY"]=>
            NULL
            ["CURRENCY"]=>
            NULL
            ["PRODUCT_PROVIDER_CLASS"]=>
            NULL
            ["VAT_INCLUDED"]=>
            NULL
            ["PRODUCT_PRICE_ID"]=>
            NULL
            ["NOTES"]=>
            NULL
            ["PRICE_TYPE_ID"]=>
            NULL
            ["BASE_PRICE"]=>
            float(0)
            ["PRICE"]=>
            NULL
            ["DISCOUNT_PRICE"]=>
            NULL
            ["CATALOG_XML_ID"]=>
            NULL
            ["PRODUCT_XML_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["DETAIL_PAGE_URL"]=>
            NULL
            ["MEASURE_NAME"]=>
            NULL
            ["MEASURE_CODE"]=>
            NULL
            ["BARCODE_MULTI"]=>
            NULL
            ["WEIGHT"]=>
            NULL
            ["DIMENSIONS"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        string(14) "SaleBasketItem"
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(0)
        ["isClone":protected]=>
        bool(false)
        ["propertyCollection":protected]=>
        object(Bitrix\\Sale\\BasketPropertiesCollection)#996 (6) {
          ["collection":protected]=>
          array(0) {
          }
          ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
          int(-1)
          ["isClone":protected]=>
          bool(false)
          ["anyItemDeleted":protected]=>
          bool(false)
          ["anyItemAdded":protected]=>
          bool(false)
          ["basketItem":protected]=>
          *RECURSION*
        }
        ["calculatedFields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#267 (5) {
          ["values":protected]=>
          array(1) {
            ["DISCOUNT_LIST"]=>
            array(0) {
            }
          }
          ["changedValues":protected]=>
          array(1) {
            ["DISCOUNT_LIST"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(1) {
            ["DISCOUNT_LIST"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["provider":protected]=>
        string(39) "\\Bitrix\\Catalog\\Product\\CatalogProvider"
        ["internalId":protected]=>
        string(2) "n1"
        ["bundleCollection":"Bitrix\\Sale\\BasketItem":private]=>
        NULL
        ["reserveQuantityCollection":protected]=>
        object(Bitrix\\Sale\\ReserveQuantityCollection)#195 (6) {
          ["collection":protected]=>
          array(1) {
            [0]=>
            object(Bitrix\\Sale\\ReserveQuantity)#1007 (5) {
              ["fields":protected]=>
              object(Bitrix\\Sale\\Internals\\Fields)#988 (5) {
                ["values":protected]=>
                array(2) {
                  ["STORE_ID"]=>
                  int(0)
                  ["QUANTITY"]=>
                  float(1)
                }
                ["changedValues":protected]=>
                array(1) {
                  ["QUANTITY"]=>
                  bool(true)
                }
                ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
                array(1) {
                  ["QUANTITY"]=>
                  NULL
                }
                ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
                array(0) {
                }
                ["isClone":protected]=>
                bool(false)
              }
              ["eventName":protected]=>
              string(20) "SaleReservedQuantity"
              ["collection":protected]=>
              *RECURSION*
              ["internalIndex":protected]=>
              int(0)
              ["isClone":protected]=>
              bool(false)
            }
          }
          ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
          int(0)
          ["isClone":protected]=>
          bool(false)
          ["anyItemDeleted":protected]=>
          bool(false)
          ["anyItemAdded":protected]=>
          bool(true)
          ["basketItem":protected]=>
          *RECURSION*
        }
      }
    }
    ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
    int(0)
    ["isClone":protected]=>
    bool(false)
    ["anyItemDeleted":protected]=>
    bool(false)
    ["anyItemAdded":protected]=>
    bool(true)
    ["siteId":protected]=>
    string(2) "s1"
    ["fUserId":protected]=>
    NULL
    ["order":protected]=>
    *RECURSION*
    ["basketItemIndexMap":protected]=>
    array(1) {
      ["n1"]=>
      int(0)
    }
    ["maxItemSort":protected]=>
    int(100)
    ["isLoadForFUserId":"Bitrix\\Sale\\BasketBase":private]=>
    bool(false)
    ["isSaveExecuting":protected]=>
    bool(false)
  }
  ["propertyCollection":protected]=>
  object(Bitrix\\Sale\\PropertyValueCollection)#925 (6) {
    ["collection":protected]=>
    array(7) {
      [0]=>
      object(Bitrix\\Sale\\PropertyValue)#934 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#576 (5) {
          ["values":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(9) "Ф.И.О."
            ["CODE"]=>
            string(3) "FIO"
            ["XML_ID"]=>
            string(16) "bx_676d191674ee4"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
          }
          ["changedValues":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(0)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#938 (1) {
          ["fields":protected]=>
          array(29) {
            ["ID"]=>
            string(1) "1"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(9) "Ф.И.О."
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(0) ""
            ["SORT"]=>
            string(3) "100"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "1"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "Y"
            ["IS_PAYER"]=>
            string(1) "Y"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "Y"
            ["CODE"]=>
            string(3) "FIO"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["SIZE"]=>
            int(40)
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [1]=>
      object(Bitrix\\Sale\\PropertyValue)#844 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1158 (5) {
          ["values":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            string(1) "2"
            ["NAME"]=>
            string(6) "E-Mail"
            ["CODE"]=>
            string(5) "EMAIL"
            ["XML_ID"]=>
            string(16) "bx_676d191674f4e"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
          }
          ["changedValues":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(1)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1140 (1) {
          ["fields":protected]=>
          array(29) {
            ["ID"]=>
            string(1) "2"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(6) "E-Mail"
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(0) ""
            ["SORT"]=>
            string(3) "110"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "1"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "Y"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "Y"
            ["CODE"]=>
            string(5) "EMAIL"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["SIZE"]=>
            int(40)
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [2]=>
      object(Bitrix\\Sale\\PropertyValue)#1147 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1146 (5) {
          ["values":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            string(1) "3"
            ["NAME"]=>
            string(14) "Телефон"
            ["CODE"]=>
            string(5) "PHONE"
            ["XML_ID"]=>
            string(16) "bx_676d191674f69"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
          }
          ["changedValues":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(2)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1145 (1) {
          ["fields":protected]=>
          array(28) {
            ["ID"]=>
            string(1) "3"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(14) "Телефон"
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(0) ""
            ["SORT"]=>
            string(3) "120"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "1"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "N"
            ["CODE"]=>
            string(5) "PHONE"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "Y"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [3]=>
      object(Bitrix\\Sale\\PropertyValue)#1150 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1149 (5) {
          ["values":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            string(1) "4"
            ["NAME"]=>
            string(12) "Индекс"
            ["CODE"]=>
            string(3) "ZIP"
            ["XML_ID"]=>
            string(16) "bx_676d191674f84"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["VALUE"]=>
            string(6) "101000"
          }
          ["changedValues":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
            ["VALUE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
            ["VALUE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(3)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1148 (1) {
          ["fields":protected]=>
          array(29) {
            ["ID"]=>
            string(1) "4"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(12) "Индекс"
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(6) "101000"
            ["SORT"]=>
            string(3) "130"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "2"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "N"
            ["CODE"]=>
            string(3) "ZIP"
            ["IS_ZIP"]=>
            string(1) "Y"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["SIZE"]=>
            int(8)
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [4]=>
      object(Bitrix\\Sale\\PropertyValue)#1153 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1152 (5) {
          ["values":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            string(1) "6"
            ["NAME"]=>
            string(28) "Местоположение"
            ["CODE"]=>
            string(8) "LOCATION"
            ["XML_ID"]=>
            string(16) "bx_676d191674fa3"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["VALUE"]=>
            string(10) "0000495241"
          }
          ["changedValues":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
            ["VALUE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
            ["VALUE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(4)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1151 (1) {
          ["fields":protected]=>
          array(28) {
            ["ID"]=>
            string(1) "6"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(28) "Местоположение"
            ["TYPE"]=>
            string(8) "LOCATION"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(10) "0000495241"
            ["SORT"]=>
            string(3) "140"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "Y"
            ["PROPS_GROUP_ID"]=>
            string(1) "2"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "N"
            ["CODE"]=>
            string(8) "LOCATION"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "5"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [5]=>
      object(Bitrix\\Sale\\PropertyValue)#1154 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1156 (5) {
          ["values":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            string(1) "5"
            ["NAME"]=>
            string(10) "Город"
            ["CODE"]=>
            string(4) "CITY"
            ["XML_ID"]=>
            string(16) "bx_676d191674fc1"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["VALUE"]=>
            string(12) "Москва"
          }
          ["changedValues":protected]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
            ["VALUE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(6) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
            ["VALUE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(5)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1155 (1) {
          ["fields":protected]=>
          array(29) {
            ["ID"]=>
            string(1) "5"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(10) "Город"
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "N"
            ["DEFAULT_VALUE"]=>
            string(12) "Москва"
            ["SORT"]=>
            string(3) "145"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "2"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "Y"
            ["CODE"]=>
            string(4) "CITY"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "N"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["SIZE"]=>
            int(40)
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
      [6]=>
      object(Bitrix\\Sale\\PropertyValue)#1143 (6) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#1138 (5) {
          ["values":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            string(1) "7"
            ["NAME"]=>
            string(27) "Адрес доставки"
            ["CODE"]=>
            string(7) "ADDRESS"
            ["XML_ID"]=>
            string(16) "bx_676d191674fe0"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
          }
          ["changedValues":protected]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            bool(true)
            ["NAME"]=>
            bool(true)
            ["CODE"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["ENTITY_TYPE"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(5) {
            ["ORDER_PROPS_ID"]=>
            NULL
            ["NAME"]=>
            NULL
            ["CODE"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["ENTITY_TYPE"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(0) {
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(6)
        ["isClone":protected]=>
        bool(false)
        ["property":protected]=>
        object(Bitrix\\Sale\\Property)#1137 (1) {
          ["fields":protected]=>
          array(31) {
            ["ID"]=>
            string(1) "7"
            ["PERSON_TYPE_ID"]=>
            string(1) "1"
            ["NAME"]=>
            string(27) "Адрес доставки"
            ["TYPE"]=>
            string(6) "STRING"
            ["REQUIRED"]=>
            string(1) "Y"
            ["DEFAULT_VALUE"]=>
            string(0) ""
            ["SORT"]=>
            string(3) "150"
            ["USER_PROPS"]=>
            string(1) "Y"
            ["IS_LOCATION"]=>
            string(1) "N"
            ["PROPS_GROUP_ID"]=>
            string(1) "2"
            ["DESCRIPTION"]=>
            string(0) ""
            ["IS_EMAIL"]=>
            string(1) "N"
            ["IS_PROFILE_NAME"]=>
            string(1) "N"
            ["IS_PAYER"]=>
            string(1) "N"
            ["IS_LOCATION4TAX"]=>
            string(1) "N"
            ["IS_FILTERED"]=>
            string(1) "N"
            ["CODE"]=>
            string(7) "ADDRESS"
            ["IS_ZIP"]=>
            string(1) "N"
            ["IS_PHONE"]=>
            string(1) "N"
            ["IS_ADDRESS"]=>
            string(1) "Y"
            ["IS_ADDRESS_FROM"]=>
            string(1) "N"
            ["IS_ADDRESS_TO"]=>
            string(1) "N"
            ["ACTIVE"]=>
            string(1) "Y"
            ["UTIL"]=>
            string(1) "N"
            ["INPUT_FIELD_LOCATION"]=>
            string(1) "0"
            ["MULTIPLE"]=>
            string(1) "N"
            ["ENTITY_TYPE"]=>
            string(5) "ORDER"
            ["MULTILINE"]=>
            string(1) "Y"
            ["COLS"]=>
            int(30)
            ["ROWS"]=>
            int(3)
            ["RELATION"]=>
            array(0) {
            }
          }
        }
      }
    }
    ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
    int(6)
    ["isClone":protected]=>
    bool(false)
    ["anyItemDeleted":protected]=>
    bool(false)
    ["anyItemAdded":protected]=>
    bool(true)
    ["order":protected]=>
    *RECURSION*
  }
  ["discount":protected]=>
  object(Bitrix\\Sale\\Discount)#917 (34) {
    ["isClone":protected]=>
    bool(false)
    ["orderRefresh":protected]=>
    bool(false)
    ["newOrder":protected]=>
    bool(true)
    ["useMode":protected]=>
    int(1)
    ["context":protected]=>
    object(Bitrix\\Sale\\Discount\\Context\\User)#1169 (3) {
      ["userId":protected]=>
      int(26)
      ["userGroups":protected]=>
      array(4) {
        [0]=>
        int(2)
        [1]=>
        int(3)
        [2]=>
        int(4)
        [3]=>
        int(6)
      }
      ["userGroupsHash":protected]=>
      string(32) "8668b1e24718c9c494266a77a0ab8c2c"
    }
    ["order":protected]=>
    *RECURSION*
    ["executeModuleFilter":protected]=>
    array(3) {
      [0]=>
      string(3) "all"
      [1]=>
      string(4) "sale"
      [2]=>
      string(7) "catalog"
    }
    ["loadedModules":protected]=>
    array(0) {
    }
    ["discountIds":protected]=>
    array(0) {
    }
    ["saleDiscountCache":protected]=>
    array(0) {
    }
    ["saleDiscountCacheKey":protected]=>
    string(0) ""
    ["basket":protected]=>
    NULL
    ["orderData":protected]=>
    array(26) {
      ["ID"]=>
      int(0)
      ["USER_ID"]=>
      int(26)
      ["USER_GROUPS"]=>
      array(4) {
        [0]=>
        int(2)
        [1]=>
        int(3)
        [2]=>
        int(4)
        [3]=>
        int(6)
      }
      ["SITE_ID"]=>
      string(2) "s1"
      ["LID"]=>
      string(2) "s1"
      ["ORDER_PRICE"]=>
      float(95)
      ["ORDER_WEIGHT"]=>
      float(0)
      ["CURRENCY"]=>
      string(3) "RUB"
      ["PERSON_TYPE_ID"]=>
      int(1)
      ["RECURRING_ID"]=>
      NULL
      ["BASKET_ITEMS"]=>
      array(1) {
        ["n1"]=>
        array(29) {
          ["MODULE"]=>
          string(7) "catalog"
          ["BASE_PRICE"]=>
          float(95)
          ["CAN_BUY"]=>
          string(1) "Y"
          ["VAT_RATE"]=>
          NULL
          ["CUSTOM_PRICE"]=>
          string(1) "N"
          ["PRODUCT_ID"]=>
          string(5) "25247"
          ["XML_ID"]=>
          string(16) "bx_676d191661847"
          ["LID"]=>
          string(2) "s1"
          ["SORT"]=>
          int(100)
          ["QUANTITY"]=>
          int(1)
          ["CURRENCY"]=>
          string(3) "RUB"
          ["PRODUCT_PROVIDER_CLASS"]=>
          string(39) "\\Bitrix\\Catalog\\Product\\CatalogProvider"
          ["VAT_INCLUDED"]=>
          string(1) "N"
          ["PRODUCT_PRICE_ID"]=>
          string(4) "6101"
          ["NOTES"]=>
          string(18) "Розничный"
          ["PRICE_TYPE_ID"]=>
          string(1) "4"
          ["PRICE"]=>
          float(95)
          ["DISCOUNT_PRICE"]=>
          float(0)
          ["CATALOG_XML_ID"]=>
          string(36) "1b9d03d6-7b26-444e-9a57-2d4180d68aaf"
          ["PRODUCT_XML_ID"]=>
          string(36) "b02ffa14-434c-11ee-8218-4ccc6a91af8f"
          ["NAME"]=>
          string(83) "Щетка шлифовальная DE-TERO Flex MB2 30*40/45*3мм 141 Р100 400J "
          ["DETAIL_PAGE_URL"]=>
          string(31) "/catalog/abraziv/de_tero/25247/"
          ["MEASURE_NAME"]=>
          string(4) "шт"
          ["MEASURE_CODE"]=>
          string(3) "796"
          ["BARCODE_MULTI"]=>
          string(1) "N"
          ["WEIGHT"]=>
          float(0)
          ["DIMENSIONS"]=>
          string(50) "a:3:{s:5:"WIDTH";N;s:6:"HEIGHT";N;s:6:"LENGTH";N;}"
          ["PROPERTIES"]=>
          array(0) {
          }
          ["ACTION_APPLIED"]=>
          string(1) "N"
        }
      }
      ["ORDER_PROP"]=>
      array(7) {
        [1]=>
        NULL
        [2]=>
        NULL
        [3]=>
        NULL
        [4]=>
        string(6) "101000"
        [6]=>
        string(10) "0000495241"
        [5]=>
        string(12) "Москва"
        [7]=>
        NULL
      }
      ["DELIVERY_LOCATION"]=>
      string(10) "0000495241"
      ["USER_EMAIL"]=>
      NULL
      ["PAYER_NAME"]=>
      NULL
      ["PROFILE_NAME"]=>
      NULL
      ["DELIVERY_LOCATION_ZIP"]=>
      string(6) "101000"
      ["BASE_PRICE_DELIVERY"]=>
      int(0)
      ["PRICE_DELIVERY"]=>
      float(0)
      ["PRICE_DELIVERY_DIFF"]=>
      float(0)
      ["DELIVERY_ID"]=>
      int(0)
      ["CUSTOM_PRICE_DELIVERY"]=>
      string(1) "N"
      ["SHIPMENT_CODE"]=>
      int(0)
      ["SHIPMENT_ID"]=>
      int(0)
      ["PAY_SYSTEM_ID"]=>
      int(0)
      ["USE_BASE_PRICE"]=>
      string(1) "N"
    }
    ["valid":protected]=>
    bool(true)
    ["saleOptions":protected]=>
    array(3) {
      ["USE_BASE_PRICE"]=>
      string(1) "N"
      ["SALE_DISCOUNT_ONLY"]=>
      string(1) "Y"
      ["APPLY_MODE"]=>
      int(0)
    }
    ["basketDiscountList":protected]=>
    array(1) {
      ["n1"]=>
      array(0) {
      }
    }
    ["basketItemsData":protected]=>
    array(1) {
      ["n1"]=>
      array(2) {
        ["BASE_PRICE_ROUND"]=>
        float(95)
        ["BASE_PRICE_ROUND_RULE"]=>
        array(0) {
        }
      }
    }
    ["discountsCache":protected]=>
    array(0) {
    }
    ["couponsCache":protected]=>
    array(0) {
    }
    ["discountResult":protected]=>
    array(5) {
      ["APPLY_BLOCKS"]=>
      array(1) {
        [0]=>
        array(3) {
          ["BASKET"]=>
          array(0) {
          }
          ["BASKET_ROUND"]=>
          array(0) {
          }
          ["ORDER"]=>
          array(0) {
          }
        }
      }
      ["DISCOUNT_LIST"]=>
      array(0) {
      }
      ["COUPON_LIST"]=>
      array(0) {
      }
      ["DELIVERY_LIST"]=>
      array(0) {
      }
      ["SHIPMENT_LIST"]=>
      array(0) {
      }
    }
    ["discountResultCounter":protected]=>
    int(0)
    ["applyResult":protected]=>
    array(0) {
    }
    ["discountStoredActionData":protected]=>
    array(0) {
    }
    ["entityList":protected]=>
    array(0) {
    }
    ["entityResultCache":protected]=>
    array(0) {
    }
    ["currentStep":protected]=>
    array(0) {
    }
    ["forwardBasketTable":protected]=>
    array(0) {
    }
    ["reverseBasketTable":protected]=>
    array(0) {
    }
    ["roundApplyMode":protected]=>
    int(4)
    ["roundApplyConfig":protected]=>
    array(0) {
    }
    ["fullDiscountList":protected]=>
    array(0) {
    }
    ["shipment":protected]=>
    NULL
    ["shipmentIds":protected]=>
    array(0) {
    }
    ["enableCheckingPrediction":protected]=>
    bool(false)
  }
  ["tax":protected]=>
  object(Bitrix\\Sale\\Tax)#1162 (7) {
    ["order":protected]=>
    *RECURSION*
    ["list":protected]=>
    array(0) {
    }
    ["availableList":protected]=>
    array(0) {
    }
    ["changedValues":protected]=>
    array(0) {
    }
    ["deliveryTax":protected]=>
    NULL
    ["isClone":protected]=>
    bool(false)
    ["isExternal":protected]=>
    bool(false)
  }
  ["internalId":protected]=>
  int(1)
  ["isNew":protected]=>
  bool(true)
  ["isSaveExecuting":protected]=>
  bool(false)
  ["isClone":protected]=>
  bool(false)
  ["isOnlyMathAction":protected]=>
  bool(false)
  ["isMeaningfulField":protected]=>
  bool(false)
  ["isStartField":protected]=>
  NULL
  ["calculateType":protected]=>
  string(1) "N"
  ["shipmentCollection":protected]=>
  object(Bitrix\\Sale\\ShipmentCollection)#800 (6) {
    ["collection":protected]=>
    array(1) {
      [0]=>
      object(Bitrix\\Sale\\Shipment)#897 (12) {
        ["fields":protected]=>
        object(Bitrix\\Sale\\Internals\\Fields)#854 (5) {
          ["values":protected]=>
          array(12) {
            ["DATE_INSERT"]=>
            object(Bitrix\\Main\\Type\\DateTime)#945 (2) {
              ["value":protected]=>
              object(DateTime)#808 (3) {
                ["date"]=>
                string(26) "2024-12-26 11:51:34.467921"
                ["timezone_type"]=>
                int(3)
                ["timezone"]=>
                string(13) "Europe/Moscow"
              }
              ["userTimeEnabled":protected]=>
              bool(true)
            }
            ["DELIVERY_ID"]=>
            string(1) "1"
            ["DELIVERY_NAME"]=>
            string(23) "Без доставки"
            ["ALLOW_DELIVERY"]=>
            string(1) "N"
            ["DEDUCTED"]=>
            string(1) "N"
            ["CUSTOM_PRICE_DELIVERY"]=>
            string(1) "N"
            ["MARKED"]=>
            string(1) "N"
            ["CANCELED"]=>
            string(1) "N"
            ["SYSTEM"]=>
            string(1) "Y"
            ["XML_ID"]=>
            string(16) "bx_676d1916723d7"
            ["RESERVED"]=>
            string(1) "Y"
            ["STATUS_ID"]=>
            string(2) "DN"
          }
          ["changedValues":protected]=>
          array(12) {
            ["DATE_INSERT"]=>
            bool(true)
            ["DELIVERY_ID"]=>
            bool(true)
            ["DELIVERY_NAME"]=>
            bool(true)
            ["ALLOW_DELIVERY"]=>
            bool(true)
            ["DEDUCTED"]=>
            bool(true)
            ["CUSTOM_PRICE_DELIVERY"]=>
            bool(true)
            ["MARKED"]=>
            bool(true)
            ["CANCELED"]=>
            bool(true)
            ["SYSTEM"]=>
            bool(true)
            ["XML_ID"]=>
            bool(true)
            ["RESERVED"]=>
            bool(true)
            ["STATUS_ID"]=>
            bool(true)
          }
          ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(12) {
            ["DATE_INSERT"]=>
            NULL
            ["DELIVERY_ID"]=>
            NULL
            ["DELIVERY_NAME"]=>
            NULL
            ["ALLOW_DELIVERY"]=>
            NULL
            ["DEDUCTED"]=>
            NULL
            ["CUSTOM_PRICE_DELIVERY"]=>
            NULL
            ["MARKED"]=>
            NULL
            ["CANCELED"]=>
            NULL
            ["SYSTEM"]=>
            NULL
            ["XML_ID"]=>
            NULL
            ["RESERVED"]=>
            NULL
            ["STATUS_ID"]=>
            NULL
          }
          ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
          array(1) {
            ["PRICE_DELIVERY"]=>
            bool(false)
          }
          ["isClone":protected]=>
          bool(false)
        }
        ["eventName":protected]=>
        NULL
        ["collection":protected]=>
        *RECURSION*
        ["internalIndex":protected]=>
        int(0)
        ["isClone":protected]=>
        bool(false)
        ["shipmentItemCollection":protected]=>
        object(Bitrix\\Sale\\ShipmentItemCollection)#912 (7) {
          ["collection":protected]=>
          array(1) {
            ["0_0"]=>
            object(Bitrix\\Sale\\ShipmentItem)#1170 (7) {
              ["fields":protected]=>
              object(Bitrix\\Sale\\Internals\\Fields)#1164 (5) {
                ["values":protected]=>
                array(3) {
                  ["XML_ID"]=>
                  string(16) "bx_676d1916768fe"
                  ["RESERVED_QUANTITY"]=>
                  float(1)
                  ["QUANTITY"]=>
                  float(1)
                }
                ["changedValues":protected]=>
                array(2) {
                  ["QUANTITY"]=>
                  bool(true)
                  ["RESERVED_QUANTITY"]=>
                  bool(true)
                }
                ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
                array(2) {
                  ["QUANTITY"]=>
                  NULL
                  ["RESERVED_QUANTITY"]=>
                  int(0)
                }
                ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
                array(0) {
                }
                ["isClone":protected]=>
                bool(false)
              }
              ["eventName":protected]=>
              NULL
              ["collection":protected]=>
              *RECURSION*
              ["internalIndex":protected]=>
              string(3) "0_0"
              ["isClone":protected]=>
              bool(false)
              ["basketItem":protected]=>
              object(Bitrix\\Sale\\BasketItem)#265 (11) {
                ["fields":protected]=>
                object(Bitrix\\Sale\\Internals\\Fields)#266 (5) {
                  ["values":protected]=>
                  array(27) {
                    ["MODULE"]=>
                    string(7) "catalog"
                    ["BASE_PRICE"]=>
                    float(95)
                    ["CAN_BUY"]=>
                    string(1) "Y"
                    ["VAT_RATE"]=>
                    NULL
                    ["CUSTOM_PRICE"]=>
                    string(1) "N"
                    ["PRODUCT_ID"]=>
                    string(5) "25247"
                    ["XML_ID"]=>
                    string(16) "bx_676d191661847"
                    ["LID"]=>
                    string(2) "s1"
                    ["SORT"]=>
                    int(100)
                    ["QUANTITY"]=>
                    int(1)
                    ["CURRENCY"]=>
                    string(3) "RUB"
                    ["PRODUCT_PROVIDER_CLASS"]=>
                    string(39) "\\Bitrix\\Catalog\\Product\\CatalogProvider"
                    ["VAT_INCLUDED"]=>
                    string(1) "N"
                    ["PRODUCT_PRICE_ID"]=>
                    string(4) "6101"
                    ["NOTES"]=>
                    string(18) "Розничный"
                    ["PRICE_TYPE_ID"]=>
                    string(1) "4"
                    ["PRICE"]=>
                    float(95)
                    ["DISCOUNT_PRICE"]=>
                    float(0)
                    ["CATALOG_XML_ID"]=>
                    string(36) "1b9d03d6-7b26-444e-9a57-2d4180d68aaf"
                    ["PRODUCT_XML_ID"]=>
                    string(36) "b02ffa14-434c-11ee-8218-4ccc6a91af8f"
                    ["NAME"]=>
                    string(83) "Щетка шлифовальная DE-TERO Flex MB2 30*40/45*3мм 141 Р100 400J "
                    ["DETAIL_PAGE_URL"]=>
                    string(31) "/catalog/abraziv/de_tero/25247/"
                    ["MEASURE_NAME"]=>
                    string(4) "шт"
                    ["MEASURE_CODE"]=>
                    string(3) "796"
                    ["BARCODE_MULTI"]=>
                    string(1) "N"
                    ["WEIGHT"]=>
                    float(0)
                    ["DIMENSIONS"]=>
                    string(50) "a:3:{s:5:"WIDTH";N;s:6:"HEIGHT";N;s:6:"LENGTH";N;}"
                  }
                  ["changedValues":protected]=>
                  array(20) {
                    ["SORT"]=>
                    bool(true)
                    ["QUANTITY"]=>
                    bool(true)
                    ["CURRENCY"]=>
                    bool(true)
                    ["PRODUCT_PROVIDER_CLASS"]=>
                    bool(true)
                    ["VAT_INCLUDED"]=>
                    bool(true)
                    ["PRODUCT_PRICE_ID"]=>
                    bool(true)
                    ["NOTES"]=>
                    bool(true)
                    ["PRICE_TYPE_ID"]=>
                    bool(true)
                    ["BASE_PRICE"]=>
                    bool(true)
                    ["PRICE"]=>
                    bool(true)
                    ["DISCOUNT_PRICE"]=>
                    bool(true)
                    ["CATALOG_XML_ID"]=>
                    bool(true)
                    ["PRODUCT_XML_ID"]=>
                    bool(true)
                    ["NAME"]=>
                    bool(true)
                    ["DETAIL_PAGE_URL"]=>
                    bool(true)
                    ["MEASURE_NAME"]=>
                    bool(true)
                    ["MEASURE_CODE"]=>
                    bool(true)
                    ["BARCODE_MULTI"]=>
                    bool(true)
                    ["WEIGHT"]=>
                    bool(true)
                    ["DIMENSIONS"]=>
                    bool(true)
                  }
                  ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
                  array(20) {
                    ["SORT"]=>
                    NULL
                    ["QUANTITY"]=>
                    NULL
                    ["CURRENCY"]=>
                    NULL
                    ["PRODUCT_PROVIDER_CLASS"]=>
                    NULL
                    ["VAT_INCLUDED"]=>
                    NULL
                    ["PRODUCT_PRICE_ID"]=>
                    NULL
                    ["NOTES"]=>
                    NULL
                    ["PRICE_TYPE_ID"]=>
                    NULL
                    ["BASE_PRICE"]=>
                    float(0)
                    ["PRICE"]=>
                    NULL
                    ["DISCOUNT_PRICE"]=>
                    NULL
                    ["CATALOG_XML_ID"]=>
                    NULL
                    ["PRODUCT_XML_ID"]=>
                    NULL
                    ["NAME"]=>
                    NULL
                    ["DETAIL_PAGE_URL"]=>
                    NULL
                    ["MEASURE_NAME"]=>
                    NULL
                    ["MEASURE_CODE"]=>
                    NULL
                    ["BARCODE_MULTI"]=>
                    NULL
                    ["WEIGHT"]=>
                    NULL
                    ["DIMENSIONS"]=>
                    NULL
                  }
                  ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
                  array(0) {
                  }
                  ["isClone":protected]=>
                  bool(false)
                }
                ["eventName":protected]=>
                string(14) "SaleBasketItem"
                ["collection":protected]=>
                object(Bitrix\\Sale\\Basket)#264 (12) {
                  ["collection":protected]=>
                  array(1) {
                    [0]=>
                    *RECURSION*
                  }
                  ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
                  int(0)
                  ["isClone":protected]=>
                  bool(false)
                  ["anyItemDeleted":protected]=>
                  bool(false)
                  ["anyItemAdded":protected]=>
                  bool(true)
                  ["siteId":protected]=>
                  string(2) "s1"
                  ["fUserId":protected]=>
                  NULL
                  ["order":protected]=>
                  *RECURSION*
                  ["basketItemIndexMap":protected]=>
                  array(1) {
                    ["n1"]=>
                    int(0)
                  }
                  ["maxItemSort":protected]=>
                  int(100)
                  ["isLoadForFUserId":"Bitrix\\Sale\\BasketBase":private]=>
                  bool(false)
                  ["isSaveExecuting":protected]=>
                  bool(false)
                }
                ["internalIndex":protected]=>
                int(0)
                ["isClone":protected]=>
                bool(false)
                ["propertyCollection":protected]=>
                object(Bitrix\\Sale\\BasketPropertiesCollection)#996 (6) {
                  ["collection":protected]=>
                  array(0) {
                  }
                  ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
                  int(-1)
                  ["isClone":protected]=>
                  bool(false)
                  ["anyItemDeleted":protected]=>
                  bool(false)
                  ["anyItemAdded":protected]=>
                  bool(false)
                  ["basketItem":protected]=>
                  *RECURSION*
                }
                ["calculatedFields":protected]=>
                object(Bitrix\\Sale\\Internals\\Fields)#267 (5) {
                  ["values":protected]=>
                  array(1) {
                    ["DISCOUNT_LIST"]=>
                    array(0) {
                    }
                  }
                  ["changedValues":protected]=>
                  array(1) {
                    ["DISCOUNT_LIST"]=>
                    bool(true)
                  }
                  ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
                  array(1) {
                    ["DISCOUNT_LIST"]=>
                    NULL
                  }
                  ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
                  array(0) {
                  }
                  ["isClone":protected]=>
                  bool(false)
                }
                ["provider":protected]=>
                string(39) "\\Bitrix\\Catalog\\Product\\CatalogProvider"
                ["internalId":protected]=>
                string(2) "n1"
                ["bundleCollection":"Bitrix\\Sale\\BasketItem":private]=>
                NULL
                ["reserveQuantityCollection":protected]=>
                object(Bitrix\\Sale\\ReserveQuantityCollection)#195 (6) {
                  ["collection":protected]=>
                  array(1) {
                    [0]=>
                    object(Bitrix\\Sale\\ReserveQuantity)#1007 (5) {
                      ["fields":protected]=>
                      object(Bitrix\\Sale\\Internals\\Fields)#988 (5) {
                        ["values":protected]=>
                        array(2) {
                          ["STORE_ID"]=>
                          int(0)
                          ["QUANTITY"]=>
                          float(1)
                        }
                        ["changedValues":protected]=>
                        array(1) {
                          ["QUANTITY"]=>
                          bool(true)
                        }
                        ["originalValues":"Bitrix\\Sale\\Internals\\Fields":private]=>
                        array(1) {
                          ["QUANTITY"]=>
                          NULL
                        }
                        ["customFields":"Bitrix\\Sale\\Internals\\Fields":private]=>
                        array(0) {
                        }
                        ["isClone":protected]=>
                        bool(false)
                      }
                      ["eventName":protected]=>
                      string(20) "SaleReservedQuantity"
                      ["collection":protected]=>
                      *RECURSION*
                      ["internalIndex":protected]=>
                      int(0)
                      ["isClone":protected]=>
                      bool(false)
                    }
                  }
                  ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
                  int(0)
                  ["isClone":protected]=>
                  bool(false)
                  ["anyItemDeleted":protected]=>
                  bool(false)
                  ["anyItemAdded":protected]=>
                  bool(true)
                  ["basketItem":protected]=>
                  *RECURSION*
                }
              }
              ["shipmentItemStoreCollection":protected]=>
              object(Bitrix\\Sale\\ShipmentItemStoreCollection)#1142 (6) {
                ["collection":protected]=>
                array(0) {
                }
                ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
                int(-1)
                ["isClone":protected]=>
                bool(false)
                ["anyItemDeleted":protected]=>
                bool(false)
                ["anyItemAdded":protected]=>
                bool(false)
                ["shipmentItem":"Bitrix\\Sale\\ShipmentItemStoreCollection":private]=>
                *RECURSION*
              }
            }
          }
          ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
          int(0)
          ["isClone":protected]=>
          bool(false)
          ["anyItemDeleted":protected]=>
          bool(false)
          ["anyItemAdded":protected]=>
          bool(true)
          ["shipment":protected]=>
          *RECURSION*
          ["shipmentItemIndexMap":protected]=>
          array(1) {
            ["n1"]=>
            string(3) "0_0"
          }
        }
        ["service":protected]=>
        object(Bitrix\\Sale\\Delivery\\Services\\EmptyDeliveryService)#1141 (19) {
          ["handlerCode":protected]=>
          string(12) "BITRIX_EMPTY"
          ["id":protected]=>
          string(1) "1"
          ["name":protected]=>
          string(23) "Без доставки"
          ["code":protected]=>
          string(0) ""
          ["vatId":protected]=>
          int(0)
          ["sort":protected]=>
          string(3) "100"
          ["logotip":protected]=>
          int(0)
          ["parentId":protected]=>
          string(1) "0"
          ["currency":protected]=>
          string(3) "RUB"
          ["active":protected]=>
          bool(true)
          ["description":protected]=>
          string(0) ""
          ["config":protected]=>
          array(1) {
            ["MAIN"]=>
            array(3) {
              ["CURRENCY"]=>
              string(3) "RUB"
              ["PRICE"]=>
              int(0)
              ["PERIOD"]=>
              array(3) {
                ["FROM"]=>
                int(0)
                ["TO"]=>
                int(0)
                ["TYPE"]=>
                string(1) "D"
              }
            }
          }
          ["restricted":protected]=>
          bool(false)
          ["trackingClass":protected]=>
          string(0) ""
          ["deliveryRequestHandler":protected]=>
          NULL
          ["extraServices":protected]=>
          object(Bitrix\\Sale\\Delivery\\ExtraServices\\Manager)#1116 (2) {
            ["items":protected]=>
            array(0) {
            }
            ["isClone":protected]=>
            bool(false)
          }
          ["trackingParams":protected]=>
          array(0) {
          }
          ["allowEditShipment":protected]=>
          string(1) "Y"
          ["isClone":protected]=>
          bool(false)
        }
        ["extraServices":protected]=>
        NULL
        ["storeId":protected]=>
        NULL
        ["internalId":protected]=>
        int(0)
        ["propertyCollection":protected]=>
        NULL
        ["isNew":protected]=>
        bool(true)
      }
    }
    ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
    int(0)
    ["isClone":protected]=>
    bool(false)
    ["anyItemDeleted":protected]=>
    bool(false)
    ["anyItemAdded":protected]=>
    bool(true)
    ["order":protected]=>
    *RECURSION*
  }
  ["paymentCollection":protected]=>
  object(Bitrix\\Sale\\PaymentCollection)#920 (6) {
    ["collection":protected]=>
    array(0) {
    }
    ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
    int(-1)
    ["isClone":protected]=>
    bool(false)
    ["anyItemDeleted":protected]=>
    bool(false)
    ["anyItemAdded":protected]=>
    bool(false)
    ["order":protected]=>
    *RECURSION*
  }
  ["tradeBindingCollection":protected]=>
  object(Bitrix\\Sale\\TradeBindingCollection)#873 (6) {
    ["collection":protected]=>
    array(0) {
    }
    ["index":"Bitrix\\Sale\\Internals\\EntityCollection":private]=>
    int(-1)
    ["isClone":protected]=>
    bool(false)
    ["anyItemDeleted":protected]=>
    bool(false)
    ["anyItemAdded":protected]=>
    bool(false)
    ["order":protected]=>
    *RECURSION*
  }
  ["printedChecks":protected]=>
  array(0) {
  }
}

`