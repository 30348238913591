import {Link, useNavigate} from "react-router-dom";
import {BASE_URL} from "../../../utils/Constants";
import bucketBtn from "../../../image/menuItems/bucketBtn.svg";
import React, {useContext, useState} from "react";
import {CustomContext} from "../../../components/contexts/ProductUsersContext";
import {EmptyCart} from "../../emptyCart/EmptyCart";
import axios from "axios";
import './initOrder.scss'

const InitOrder = () => {
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

    const {
        order,
        removeBucket,
        incQuantity,
        decQuantity,
        userPersonalData,
        setOrders,
        userProfile,
        setProducts,
    } = useContext(CustomContext);
    const navigate = useNavigate();

    const totalPrice = order.reduce((sum, element) => {
        return sum + element.price * element.quantity;
    }, 0);

    const orderItems = order.map((item) => {
        const itemPrice = item.price * item.quantity;
        return {
            ...item,
            totalItemPrice: itemPrice,
        };
    });

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const handleButtonPay = () => {
        const products = order.map((item) => {
            return {
                productId: item.id,
                quantity: item.quantity,
            };
        });
        axios
            .post(`${BASE_URL}/local/API/v0.1/order/make/`, {
                    products: products,
                    userId: +userPersonalData.userData.id,
                    userComment: "свяжитель со мной!",
                    deliveryAddress: deliveryAddress,
                },
                {
                    withCredentials: false,
                    headers: {
                        "Content-Type": "text/plain",
                    },
                }
            )
            .then((resp) => {
                if (resp?.data?.orderId) {
                    setOrders([]);
                    localStorage.removeItem("cartItems");
                    navigate(`/ordering/success/${resp.data.orderId}`);
                }
            }).catch(err => {
            console.log('err => ', err)
        });
    };

    const handleRemoveOrderItem = (id) => {
        removeBucket(id);
        const updatedOrder = order.filter((item) => item.id !== id);
        setProducts(updatedOrder);

        if (updatedOrder.length > 0) {
            localStorage.setItem("cartItems", JSON.stringify(updatedOrder));
        } else {
            localStorage.removeItem("cartItems");
        }
    };

    const handleIncreaseQuantity = (id) => {
        incQuantity(id);
        const updatedOrder = order.map((item) =>
            item.id === id ? {...item, quantity: item.quantity + 1} : item
        );
        setProducts(updatedOrder);
        localStorage.setItem("cartItems", JSON.stringify(updatedOrder));
    };

    const handleDecreaseQuantity = (id) => {
        if (order.some((item) => item.id === id && item.quantity > 1)) {
            decQuantity(id);
            const updatedOrder = order.map((item) =>
                item.id === id ? {...item, quantity: item.quantity - 1} : item
            );
            setProducts(updatedOrder);
            localStorage.setItem("cartItems", JSON.stringify(updatedOrder));
        } else {
            handleRemoveOrderItem(id);
        }
    };


    if (order.length === 0) {
        return <EmptyCart/>;
    } else {
        return (
            <div className="content_block">
                <div className="mainBox">
                    <div className="page_title">
                        <h1>Оформление заказа</h1>
                    </div>
                    <div className="orderBox">
                        <div className="info">
                            {userPersonalData.length === 0 ? (
                                <div className="errorBox">
                                    <span>Для продолжения Вам нужно авторизоваться</span>
                                    <Link to="/login">
                                        <br/>
                                        <span className="buttonAuth">
                                          Перейти к авторизации
                                        </span>
                                    </Link>
                                </div>
                            ) : (
                                <>
                                    <div className="contacts">
                                        <p className="name">{userPersonalData.userData.fio}</p>
                                        <p className="email">{userPersonalData.userData.email}</p>
                                    </div>

                                    <div className="adress">
                                        <span className="adressTitle">Адрес доставки</span>
                                        <div className="input-wrapper">
                                            <input
                                                onChange={(e) => setDeliveryAddress(e.target.value)}
                                                type="email"
                                                placeholder="Введите адрес доставки"
                                            />
                                        </div>
                                    </div>
                                    <div className="payment">
                                        <label htmlFor="paymentMethodSelect">
                                            Выберите способ оплаты:
                                        </label>
                                        <select
                                            id="paymentMethodSelect"
                                            className="paymentMethodSelect"
                                            value={selectedPaymentMethod}
                                            onChange={handlePaymentMethodChange}
                                        >
                                            <option value="Наличные">Наличные</option>
                                            <option value="Перевод">Перевод</option>
                                            <option value="Безналичный расчет">
                                                Безналичный расчет
                                            </option>
                                        </select>
                                    </div>

                                    <div className="finish">
                                        <span className="money">{totalPrice} руб.</span>
                                        <button className="pay" onClick={handleButtonPay}>
                                            Оформить заказ
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="list">
                            {orderItems.map((items) => (
                                <div className="item" key={items.id}>
                                    <div className="imageBox">
                                        <img src={BASE_URL + items.picture} alt={items.name}/>
                                    </div>
                                    <div className="infoBox">
                                        <div className="title">
                                            <div>{items.name}</div>
                                        </div>
                                        <div className="priceBox">
                                            <span className="price">{items.totalItemPrice} руб.</span>
                                            {items.oldPrice && (
                                                <span className="action">{items.oldPrice} руб.</span>
                                            )}
                                        </div>
                                        <div className="amounts">
                                            <button
                                                className="btnMinus"
                                                onClick={() =>
                                                    items.quantity <= 1
                                                        ? handleRemoveOrderItem(items.id)
                                                        : handleDecreaseQuantity(items.id)
                                                }
                                            >
                                                -
                                            </button>
                                            <span className="amount">{items.quantity}</span>
                                            <button
                                                className="btnPlus"
                                                onClick={() => handleIncreaseQuantity(items.id)}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div className="buttons">
                                            <button
                                                className="delete"
                                                onClick={() => handleRemoveOrderItem(items.id)}
                                            >
                                                <img src={bucketBtn} alt="Удалить"/>
                                                Удалить заказ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="total">Итого: {totalPrice} руб.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default InitOrder;
