import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const storedUserData = JSON.parse(localStorage.getItem("userPersonalData"));

  if (!storedUserData || !storedUserData.userData) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
