import React from "react";
import "./ordering.scss";
import {Outlet} from "react-router-dom";

function Ordering() {
  return (
      <Outlet/>
  );
}

export default Ordering;
