import React, {useContext, useEffect, useState} from "react";
import "./edit.scss";
import fioIMG from "../../image/menuItems/fio.svg";
import emailIMG from "../../image/menuItems/email.svg";
import adressIMG from "../../image/menuItems/adress.svg";
import phoneIMG from "../../image/menuItems/phone.svg";
import companyIMG from "../../image/menuItems/company.svg";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import {BASE_URL} from "../../utils/Constants";

const schema = yup.object().shape({
  fio: yup.string().required("Имя обязательно"),
  email: yup
      .string()
      .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Введите корректный email"
      )
      .required("Email обязателен"),
  address: yup.string().required("Адрес обязателен"),
  phone: yup
      .string()
      .required("Номер телефона обязателен"),
  companyName: yup.string().required("Название компании обязательно"),
});

const Edit = () => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const { setUserPersonalData, userPersonalData: {userData} } = useContext(CustomContext);

  const methods = useForm({
    defaultValues: {
      fio: "",
      email: "",
      address: "",
      phone: "",
      companyName: "",
    },
    mode: "onBlur",
  });

  const { control, setValue, handleSubmit, setError, formState: { errors } } = methods;

  useEffect(() => {
    if (userData) {
      setValue('fio', userData?.fio, { shouldValidate: true });
      setValue('email', userData?.email, { shouldValidate: true });
      setValue('address', userData?.address, { shouldValidate: true });
      setValue('phone', userData?.phone, { shouldValidate: true });
      setValue('companyName', userData?.companyName, { shouldValidate: true });
    }
  }, [userData, setValue]);

  const onSubmit = async (data) => {
    try {
      await schema.validate(data, { abortEarly: false });
      const payload = {
        userId: +userData.id,
        userInformation: data,
      };

      setDisableSubmit(true)
      axios
          .post(`${BASE_URL}/local/API/v0.1/user/updateUserById/`, payload)
          .then((response) => {
            if (response.data.status === "success") {
              setUserPersonalData({userData: response?.data?.userData});
              localStorage.setItem("userPersonalData", JSON.stringify({userData: response?.data
            }?.userData));
            }
          }).finally(() => {
            setDisableSubmit(false)
      });
    } catch (validationErrors) {
      setDisableSubmit(false)
      validationErrors.inner.forEach((error) => {
        setError(error.path, { type: "manual", message: error.message });
      });
    }
  };

  return (
      <div className="content_block">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="edit">
            <span className="title">Редактирование</span>

            {/* Поле для имени */}
            <div className="input-group">
              <label htmlFor="name">Имя:</label>
              <div className="input-wrapper">
                <img src={fioIMG} alt="Иконка имени" className="icon" />
                <Controller
                    name="fio"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <input
                            type="text"
                            placeholder="Введите ваше имя"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
              </div>
              {errors.name && <p className="error">{errors.name.message}</p>}
            </div>

            {/* Поле для Email */}
            <div className="input-group">
              <label htmlFor="email">Email:</label>
              <div className="input-wrapper">
                <img src={emailIMG} alt="Иконка email" className="icon" />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <input
                            type="email"
                            placeholder="Введите ваш email"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
              </div>
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>

            {/* Поле для адреса */}
            <div className="input-group">
              <label htmlFor="adress">Адрес:</label>
              <div className="input-wrapper">
                <img src={adressIMG} alt="Иконка адреса" className="icon" />
                <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <input
                            type="text"
                            placeholder="Введите ваш адрес"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
              </div>
              {errors.address && <p className="error">{errors.address.message}</p>}
            </div>

            {/* Поле для номера телефона */}
            <div className="input-group">
              <label htmlFor="phone">Номер телефона:</label>
              <div className="input-wrapper">
                <img src={phoneIMG} alt="Иконка телефона" className="icon" />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <input
                            type="tel"
                            placeholder="Введите ваш номер телефона"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
              </div>
              {errors.phone && <p className="error">{errors.phone.message}</p>}
            </div>

            {/* Поле для названия компании */}
            <div className="input-group">
              <label htmlFor="company">Название компании:</label>
              <div className="input-wrapper">
                <img src={companyIMG} alt="Иконка компании" className="icon" />
                <Controller
                    name="companyName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <input
                            type="text"
                            placeholder="Введите название компании"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
              </div>
              {errors.companyName && <p className="error">{errors.companyName.message}</p>}
            </div>

            {/* Кнопка для обновления */}
            <button disabled={disableSubmit} type="submit">Обновить данные</button>
          </form>
        </FormProvider>
      </div>
  );
};

export default Edit;
