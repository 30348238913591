import './orderSuccess.scss'
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {BASE_URL} from "../../../utils/Constants";
import axios from "axios";
const OrderSuccess = () => {
    const {id: orderId} = useParams();
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/local/API/v0.1/order/history/show/`, {
                    orderId
                });
                setOrderDetails(response?.data);
            } catch (err) {
                console.log('err', err)
            }
        };

        if (orderId) {
            fetchOrderDetails();
        }
    }, [orderId]);


    useEffect(() => {
        console.log('orderDetails', orderDetails)
    }, [orderDetails]);

    return (
        <div className='container'>
            <div className='order_success_header'>
                <h2>Заказ сформирован</h2>
                <Link to="/cart">
                    В корзину
                </Link>
            </div>
            <div className='success_content'>
                <div className='content_block'>
                    <div className='success_icon'/>
                    <img src="/success-icon.svg" alt="success icon"/>
                    <div className='content_info'>
                        <p>
                            Ваш заказ <b>№{orderDetails?.orderId}</b>
                            от {orderDetails?.orderDateCreate} успешно создан. Номер вашей оплаты: <b>№{orderDetails?.orderId}/1</b>
                        </p>
                        <p>
                            Вы можете следить за выполнением своего заказа в <Link to={"/"}>Персональном разделе
                            сайта.</Link> Обратите внимание, что для входа в этот раздел вам необходимо будет ввести логин
                            и пароль пользователя сайта.
                        </p>
                    </div>
                    <div className='success_footer'>
                        <div className='card_block'>
                            <p>Оплата заказа</p>
                            <div className='info'>
                                <div className="img_block">
                                    <img src="/card.png" alt="card"/>
                                </div>
                                <p>Наличные курьеру</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderSuccess
