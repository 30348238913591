import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomContext } from "../../components/contexts/CatalogContext";
import axios from "axios";
import "./catalog.scss";
import { BASE_URL } from "../../utils/Constants";

function Catalog() {
  const { catalogData, setCatalog } = useContext(CustomContext);
  const [selectedParent, setSelectedParent] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/local/API/v0.1/shop/sections/get/`)
      .then((response) => {
        setCatalog(response.data.sections);
      });
  }, []);

  const groupedData = {};

  catalogData.forEach((element) => {
    if (element.isHaveSubsections === true) {
      groupedData[element.id] = { parentTitle: element, childrenTitle: [] };
    } else {
      const parentTitle = groupedData[element.parentSectionId];
      if (parentTitle) {
        parentTitle.childrenTitle.push(element);
      }
    }
  });

  const menuTitle = Object.values(groupedData).map(
    ({ parentTitle, childrenTitle }) => ({
      parentTitle,
      childrenTitle,
    })
  );

  const handleParentClick = (parentId) => {
    setSelectedParent(parentId);
  };

  const renderSubcategories = (childrenTitle) => (
    <div className="subcategories">
      {childrenTitle.map((child) => (
        <Link
          to={`/category/${child.id}`}
          className="child-link"
          key={child.id}
        >
          <span className="child-title">{child.name}</span>
        </Link>
      ))}
    </div>
  );

  return (
    <div className="catalog-page">
      <h1 className="catalog-title">Каталог</h1>
      <div className="breadcrumb">Главная — Каталог</div>
      <div className="catalog-grid">
        {menuTitle.map((data) => (
          <div className="catalog-item" key={data.parentTitle.id}>
            <div
              className="parent-link"
              onClick={() => handleParentClick(data.parentTitle.id)}
            >
              <span className="parent-title">{data.parentTitle.name}</span>
            </div>
            {selectedParent === data.parentTitle.id &&
              renderSubcategories(data.childrenTitle)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Catalog;
