import React from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarMenu, iconMap } from "../../utils/Constants";
import "./sidebar.scss";

function Sidebar() {
  const location = useLocation();

  const isActive = (url) => {
    if (url === "") {
      return location.pathname === "/profile";
    }
    return location.pathname.endsWith(url);
  };
  return (
    <div className="sidebar">
      {sidebarMenu.map((menu) => (
        <div
          className={`item ${isActive(menu.url) ? "active" : ""}`}
          key={menu.id}
        >
          <Link to={menu.url}>
            {iconMap[menu.icon]}
            <span className="title">{menu.title}</span>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
