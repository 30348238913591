import React, { useState } from "react";
import "./restore.scss";
import Email from "../../image/menuItems/email.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
      .string()
      .email("Введите корректный email.")
      .required("Email не может быть пустым."),
});

const Restore = () => {
  const [restoreData, setRestoreData] = useState({ email: "" });
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  const handleChangeRestore = (event) => {
    setRestoreData({
      ...restoreData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRestoreButton = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(restoreData);
    } catch (validationError) {
      setErrorText(validationError.message);
      return;
    }

    axios
      .post(`${BASE_URL}/local/API/v0.1/user/restorePassword/`, {
        login: restoreData.email,
        email: restoreData.email,
      })
      .then((response) => {
        if (response.data.status === "error") {
          const cleanMessage = response.data.message.replace(/<\/?[^>]+(>|$)/g, "");
          setErrorText(cleanMessage);
          return;
        }
        navigate("/");
      });
  };

  console.log('errorText', errorText)

  return (
    <div className="formContainer2">
      <div className="formWrapper2">
        <span className="title">Восстановление пароля</span>
        Если вы забыли пароль, введите email.
        <form>
          <div className="email">
            <img src={Email} alt="" />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={restoreData.email}
              onChange={handleChangeRestore}
            />
          </div>
          <div className="errorText">{errorText}</div>
          <button onClick={handleRestoreButton}>Восстановить</button>
        </form>
      </div>
    </div>
  );
};

export default Restore;
