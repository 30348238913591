import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./registration.scss";
import Email from "../../image/menuItems/email.svg";
import Lock from "../../image/menuItems/lock.svg";
import Name from "../../image/menuItems/user.svg";
import Phone from "../../image/menuItems/phone.svg";
import Eye from "../../image/menuItems/eye.svg";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

const Registrations = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    fio: yup.string().required("ФИО не может быть пустым."),
    email: yup
        .string()
        .email("Введите корректный email.")
        .required("Email не может быть пустым."),
    phone: yup
        .string()
        .matches(/^\+?\d{10,15}$/, "Введите корректный номер телефона.")
        .required("Телефон не может быть пустым."),
    password: yup
        .string()
        .min(6, "Пароль должен быть не менее 6 символов.")
        .required("Пароль не может быть пустым."),
    agreement: yup
        .boolean()
        .oneOf([true], "Вы должны согласиться с политикой конфиденциальности."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/local/API/v0.1/user/registrate/`, {
        fio: data.fio,
        email: data.email,
        phone: data.phone,
        password: data.password,
      });

      if (response.data.status === "error") {
        setErrorText(response?.data?.message);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Ошибка регистрации:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
      <div className="formContainerRegistr">
        <div className="formWrapperRegistr">
          <span className="titleRegist">Регистрация</span>
          <form className="formRegistr" onSubmit={handleSubmit(onSubmit)}>
            <div className="name">
              <img src={Name} alt="Name Icon" />
              <input
                  type="text"
                  placeholder="ФИО"
                  {...register("fio")}
              />
            </div>
            {errors.fio && <div className="errorMessage">{errors.fio.message}</div>}
            <div className="email">
              <img src={Email} alt="Email Icon" />
              <input
                  type="email"
                  placeholder="Email"
                  {...register("email")}
              />
            </div>
            {errors.email && <div className="errorMessage">{errors.email.message}</div>}
            <div className="phone">
              <img src={Phone} alt="Phone Icon" />
              <input
                  type="text"
                  placeholder="Телефон"
                  {...register("phone")}
              />
            </div>
            {errors.phone && <div className="errorMessage">{errors.phone.message}</div>}
            <div className="password">
              <img src={Lock} alt="Password Icon" />
              <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  {...register("password")}
                  className="inputPassword"
              />
              <img
                  src={Eye}
                  alt={showPassword ? "Hide" : "Show"}
                  onClick={togglePasswordVisibility}
                  className="eyeIcon"
              />
            </div>
            {errors.password && <div className="errorMessage">{errors.password.message}</div>}
            <div className="checkboxContainer">
              <input
                  type="checkbox"
                  {...register("agreement")}
              />
              <span>Я согласен с политикой конфиденциальности</span>
            </div>
            {errors.agreement && <div className="errorMessage">{errors.agreement.message}</div>}
            {errorText && <div className="errorMessage">{errorText}</div>}
            <button className="registrationBtn" type="submit">
              Зарегистрироваться
            </button>
            <span className="infoRegistrSpan">
            Есть аккаунт?{" "}
              <Link className="link" to={"/login"}>
              Войти
            </Link>
          </span>
          </form>
        </div>
      </div>
  );
};

export default Registrations;
