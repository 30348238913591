import React, { useContext, useEffect } from "react";
import Menu from "../menu/Menu";
import "./navbar.scss";
import Logo from "../../image/logo-stanki05.png";
import { ReactComponent as BucketIcon } from "../../image/menuItems/bucket.svg";
import userImg from "../../image/menuItems/user.svg";
import { Link } from "react-router-dom";
import { CatalogContext } from "../contexts/CatalogContext";
import { CustomContext } from "../contexts/ProductUsersContext";
import axios from "axios";
import Search from "../search/Search";
import { BASE_URL } from "../../utils/Constants";

function Navbar({setSearchText}) {
  const {
    order,
    setUserPersonalData,
    setUserError,
    userError,
  } = useContext(CustomContext);

  useEffect(() => {
    try {
      const storedUserData = localStorage.getItem("userPersonalData");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        axios
            .post(`${BASE_URL}/local/API/v0.1/user/getUserById/`, {
              userId: userData?.userData?.id
            })
            .then((response) => {
              if (response.data.status === "error") {
                setUserError(false);
              } else {
                setUserError(true);
                setUserPersonalData({userData: response?.data?.userData});
              }
            });
      }
    } catch {
      setUserError(false);
    }
  }, []);


  return (
    <CatalogContext>
      <>
        <div className="top-wrapper">
          <div className="content_block">
            <div className="adress">г. Махачкала, ул. Булача, 17Д</div>
            <div className="phone">8 8722 777 711</div>
            <div className="auth">
              {userError ? (
                <Link to={"/profile"}>
                  <img src={userImg} alt="" className="icon" />
                </Link>
              ) : (
                <Link to={"/login"}>
                  <img src={userImg} alt="" className="icon" />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="content_block">
            <div className="logo">
              <Link to={"/"}>
                <img src={Logo} alt="" />
              </Link>
            </div>
            <div className="top-description">Всё для Вашего производства</div>
            <Search setSearchText={setSearchText} />
            <div className="icons">
              <div className="cart">
                <Link to={"/cart"}>
                  <BucketIcon className="iconBucket" />
                  {/* <img
                    src={bucketImg}
                    alt=""
                    className="iconsBucket"
                    style={bucketIconStyle}
                  /> */}
                </Link>
                <span>{order.length}</span>
              </div>
            </div>
          </div>
        </div>
        <Menu />
      </>
    </CatalogContext>
  );
}

export default Navbar;
