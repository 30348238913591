import React from "react";
import "./home.scss";
import Items from "../../components/items/Items";
import Offers from "../../components/offers/Offers";
import {useOutletContext} from "react-router-dom";

function Home() {
    const { searchText } = useOutletContext();
    return (
    <div className="home">
      <Offers />
      <Items searchText={searchText} />
    </div>
  );
}

export default Home;
