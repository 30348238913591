export function productUsersReducer(state, { type, payload }) {
  switch (type) {
    case "SET_PRODUCTS":
      return { ...state, productData: payload || [] };

    case "SET_ORIGINAL_PRODUCT":
      return { ...state, originalProductData: payload || [] };

    case "SET_ORDERS": {
       return {
        ...state,
        order: payload || [],
      };
    }
    case "ADD_TO_BUCKET": {
      const itemIndex = state.order.findIndex(
        (orderItem) => orderItem.id === payload.id
      );
      let newOrder = null;
      if (itemIndex < 0) {
        const newItem = {
          ...payload,
          quantity: 1,
        };
        newOrder = [...state.order, newItem];
      } else {
        newOrder = state.order.map((orderItem, index) => {
          if (index === itemIndex) {
            return { ...orderItem, quantity: orderItem.quantity + 1 };
          } else {
            return orderItem;
          }
        });
      }
      return {
        ...state,
        order: newOrder,
      };
    }

    case "INC_QUANTITY":
      return {
        ...state,
        order: state.order.map((element) => {
          if (element.id === payload.id) {
            const newQuantity = element.quantity + 1;
            return {
              ...element,
              quantity: newQuantity,
            };
          }
          return element;
        }),
      };

    case "DEC_QUANTITY":
      return {
        ...state,
        order: state.order.map((element) => {
          if (element.id === payload.id) {
            const newQuantity = element.quantity - 1;
            return {
              ...element,
              quantity: newQuantity >= 0 ? newQuantity : 0,
            };
          }
          return element;
        }),
      };

    case "REMOVE_BUCKET":
      return {
        ...state,
        order: state.order.filter((product) => product.id !== payload.id),
      };

    case "CLEAR_BUCKET":
      return {
        ...state,
        order: [],
      };

    case "SET_RECOMMENDED_PRODUCT_DATA":
      return {
        ...state,
        recommendedProductData: payload || [],
      };

    case "SET_POPULAR_PRODUCT_DATA":
      return {
        ...state,
        popularProductData: payload || [],
      };

    case "SET_CATEGORY_PRODUCTS":
      return {
        ...state,
        categoryProducts: payload || [],
      };

    case "SET_USER_AUTH":
      return {
        ...state,
        userInfo: payload || [],
      };

    case "SET_USER_PERSONAL_DATA":
      return {
        ...state,
        userPersonalData: payload || [],
      };

    case "SET_USER_ERROR":
      return {
        ...state,
        userError: payload || false,
      };

    case "SET_USER_PROFILE":
      return {
        ...state,
        userProfile: payload || [],
      };

    case "SET_SLIDER_DATA":
      return {
        ...state,
        sliderData: payload || [],
      };

    case "SET_HISTORY_ORDER":
      return {
        ...state,
        historyOrder: payload || [],
      };

    default:
      return state;
  }
}
